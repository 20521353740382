import * as React from "react"
import Helmet from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"

const PrivacyPage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Statement - Hyperfluid Solutions</title>
      </Helmet>
      <div className="relative bg-white overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-8">
          <Header></Header>
        </div>
        <div className="hidden lg:block lg:absolute lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="font-serif relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Privacy Policy
              </span>
            </h1>
            {/* <p className="mt-8 text-xl text-gray-500 leading-8">
                            Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
                            aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
                            egestas fringilla sapien.
                        </p> */}
          </div>
          <div className="mt-6 prose prose-purple prose-lg text-gray-500 mx-auto">
            <h2>Our commitment to privacy</h2>
            <p>
              Your privacy is important to us. To better protect your privacy we
              provide this notice explaining our information practices and the
              choices you can make about the way your information is collected
              and used. To make this notice easy to find, we make it available
              on every pages of our website.
            </p>

            <h2>The information we collect</h2>
            <p>
              This notice applies to all information collected or submitted on
              the Hyperfluid Solution Corp.’s website or via phone with a
              Hyperfluid Solutions Corp. representative. The types of
              information collected are personal, billing and project related.
              We might also collect information from you regarding third parties
              in relation to you (i.e. hosting company etc…).
            </p>

            <h2>The way we use information</h2>
            <p>
              We use the information you provide about yourself to provide
              services. We do not share this information with outside parties
              except to the extent necessary to complete your request for our
              services. Hyperfluid Solutions Corp. reserves the right to reveal
              this information pursuant to a valid subpoena or court order.
            </p>
            <p>
              We use return email addresses to answer the email we receive for
              tech support, customer service, tell-a-friend, email updates. Such
              addresses are not used for any other purpose and are not shared
              with outside parties.
            </p>
            <p>
              Finally, we never use or share the personally identifiable
              information provided to us online, via phone, or in person, in
              ways unrelated to the ones described above without also providing
              you an opportunity to opt-out or otherwise prohibit such unrelated
              uses.
            </p>

            <h2>Our commitment to data security</h2>
            <p>
              To prevent unauthorized access, maintain data accuracy, and ensure
              the correct use of information, we have put in place appropriate
              physical, electronic, and managerial procedures to safeguard and
              secure the information we collect online and offline.
            </p>
            <p>
              All online credit card transactions are performed only on secure
              servers through a secure SSL connection and comply with the latest
              standards in the industry. All billing information, including
              credit cards information, are deleted upon termination of the
              project with the client.
            </p>

            <h2>Third-party websites</h2>
            <p>
              This website may provide links to third-party websites. Any such
              links are provided solely as a convenience to you. Hyperfluid
              Solutions Corp. has no control over these websites or their
              content and does not assume any responsibility or liability for
              these websites. Hyperfluid Solutions Corp. does not endorse or
              make any representations about these websites, or any information,
              materials, or products found thereon. If you access any of the
              third-party websites linked on this web site, you do so at your
              own risk.
            </p>

            <h2>How you can access or correct your information</h2>
            <p>
              At any time, you may later review or update the personal
              information we have collected online from you by contacting us at:{" "}
              <a
                href="mailto:hello@hyperfluidsolutions.com"
                target="_blank"
                rel="noreferrer"
              >
                hello@hyperfluidsolutions.com
              </a>
            </p>
            <p>
              To protect your privacy and security, we will also take reasonable
              steps to verify your identity before granting access to making
              corrections.
            </p>

            <h2>How to contact us</h2>
            <p>
              Should you have other questions or concerns about these privacy
              policies, please send us an email at:{" "}
              <a
                href="mailto:hello@hyperfluidsolutions.com"
                target="_blank"
                rel="noreferrer"
              >
                hello@hyperfluidsolutions.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PrivacyPage;
